'use strict';

$(document).ready(function () {
    if (screen && screen.width > 768) {
        $('#fullpage').fullpage({
            lockAnchors: false,
            anchors: ['etusivu', 'palvelut', '3d_tulostus', 'footer']
        });
        var scrollHeight = $('.slim-scroll').parent().height();
        $('.slim-scroll').slimScroll({
            position: 'left',
            height: ((scrollHeight / 5) * 3),
            railVisible: true,
            alwaysVisible: true,
            color: '#fff',
            railColor: "#000",
            disableFadeOut: true
        });
    }
    //https://mattboldt.com/typed.js/docs/
    window.setTimeout(function () {
        var typed = new Typed('.shinovent-title-big-1', {
            strings: [
                "",
                "新 [shin, \"new\"]",
                "Innovate",
                "Invent",
                "SHIN^30",
                "SHINO^30",
                "SHINOVENT^3000"
            ],
            typeSpeed: 60,
            smartBackspace: true,
            showCursor: false,
            backSpeed: 20,
            loop: !0,
            backDelay: 1000,
            startDelay: 3000
        });
    }, 3000);

});
